* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif;
}


.contactEmail {
  color: #2596be
}

.contactName {
  color: #001345;
font-weight: bold;
  text-decoration: none;
}

.sc-gKPRtg {
  color: black
}

 #rcc-decline-button {

  background-color: rgb(156, 0, 0) !important;
  border-radius: 10px !important;
}

#rcc-confirm-button {

  background-color: rgb(3, 161, 45) !important;
  color: white !important;
  border-radius: 10px !important;

}

#onetrust-policy-text a{

  color: white;
}

.CookieConsent {
  display: block !important;
  padding-top: 25vh;
  padding-bottom: 10vh;
  padding-left: 5% !important;
  padding-right: 5% !important;
  background: #0f2934e8 !important;
  bottom: 30% !important;
position: sticky !important;

} 